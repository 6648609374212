import { dateFormatterToSend } from 'Utils/formatter';

export default {
  data() {
    return {
      filterValues: {
        offset: 0,
        search: '',
        searchSelect: 'property-name',

        modal: {
          manager: [],
          finance: [],
          expenseCategory: [],
          transferDestination: [],
          startDate: null,
          endDate: null,
        },
      },

      filterLoading: false,

      filterOptions: {
        searchbar: [
          {
            val: 'property-name',
            label: 'Nama Properti',
          },
          // TODO fix val key value when working on the search input
          {
            val: 'foo',
            label: 'ID',
          },
          {
            val: 'bar',
            label: 'No. Invoice Biaya',
          },
          {
            val: 'bar',
            label: 'Nama Pengeluaran',
          },
        ],
        confirmationStatus: [
          {
            value: 'unconfirmed',
            label: 'Menunggu Konfirmasi',
          },
          {
            value: 'confirmed',
            label: 'Dikonfirmasi',
          },
          {
            value: 'rejected',
            label: 'Ditolak',
          },
        ],
        expenseCategory: [],
        transferDestination: [],
      },
    };
  },

  computed: {
    filterExpenditure() {
      return {
        offset: this.filterValues.offset,
        approval_status_manager: this.filterValues.modal.manager,
        approval_status_finance: this.filterValues.modal.finance,
        expense_category_id: this.filterValues.modal.expenseCategory,
        vendor_id: this.filterValues.modal.transferDestination,
        created_start: this.filterValues.modal.startDate
          ? dateFormatterToSend(this.filterValues.modal.startDate)
          : '',
        created_end: this.filterValues.modal.endDate
          ? dateFormatterToSend(this.filterValues.modal.endDate)
          : '',
      };
    },
    fromFiltering() {
      let total = 0;
      const values = Object.values(this.filterValues.modal);
      values.forEach(value => {
        if (value !== null && value.length !== 0) total += 1;
      });

      return total > 0;
    }
  },

  methods: {
    onFilterChange(value) {
      this.filterValues = { ...value };
    },
    dateFormatterToSend(value) {
      return dateFormatterToSend(value);
    },
  },
};