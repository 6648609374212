<template>
  <section class="owner-expenditure" data-testid="owner-expenditure">
    <bg-grid>
      <bg-grid-item :col="12" class="mb-0">
        <filters
          :filter-values="filterValues"
          :filter-loading="filterLoading"
          :filter-options="filterOptions"
          @go-to-add-page="
            $router.push({ name: 'other-transaction.expenditure.add' })
          "
          @on-dropdown-open="fetchGetExpenditureFilterVendor"
          @on-filter-change="onFilterChange"
          @reset-filter="resetFilter"
          @submit-search="onSubmitSearch"
        />
      </bg-grid-item>

      <bg-grid-item :col="12">
        <expandable-transaction-table
          :data="transactions"
          :loading="isLoading.expenses"
          :from-filtering="fromFiltering"
          @expand-content="expandContent"
          @handle-action="showActionModal"
        />

        <div class="mt-24 ta-c">
          <bg-skeleton
            v-if="isLoading.pagination"
            width="200px"
            height="32px"
            class="m-middle"
            data-testid="loader"
          />
          <template v-else>
            <bg-pagination
              v-if="pagination.totalPagination > 1"
              v-model="pagination.page"
              :page-total="pagination.totalPagination"
              @click="onPaginationChange"
              data-testid="pagination"
            />
          </template>
        </div>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="showModal.confirmation"
      desktop-size="sm"
      :closable="false"
      title="Yakin ingin konfirmasi data ini?"
      description="Data yang telah dikonfirmasi akan dilanjutkan ke tahap selanjutnya."
      data-testid="confirmation-modal"
    >
      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            size="lg"
            data-testid="close-modal"
            :loading="isLoading.action"
            @click="closeModal"
          >
            Kembali
          </bg-button>
          <bg-button
            variant="primary"
            size="lg"
            data-testid="confirm-expenditure"
            :loading="isLoading.action"
            @click="fetchAction"
          >
            Konfirmasi
          </bg-button>
        </div>
      </template>
    </bg-modal>

    <bg-modal
      v-model="showModal.deletion"
      desktop-size="sm"
      :closable="false"
      title="Yakin hapus data ini?"
      description="Data yang dihapus tidak dapat dikembalikan."
      data-testid="deletion-modal"
    >
      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            size="lg"
            data-testid="close-modal"
            :loading="isLoading.action"
            @click="fetchAction"
          >
            Hapus
          </bg-button>
          <bg-button
            variant="primary"
            size="lg"
            data-testid="delete-expenditure"
            :loading="isLoading.action"
            @click="closeModal"
          >
            Batal
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgPagination,
  BgSkeleton,
  BgModal,
} from 'bangul-vue';
import { endpoints } from '@admin/api/endpoints/other-transaction-expenditure.js';
import filterMixin from '../_mixins/filterMixin.js';

const LIMIT = 10;

export default {
  name: 'OwnerExpenditure',

  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgPagination,
    BgSkeleton,
    BgModal,
    ExpandableTransactionTable: () =>
      import('./_components/ExpandableTransactionTable'),
    Filters: () => import('@admin_pages/OtherTransaction/_components/Filters'),
  },

  mixins: [filterMixin],

  data() {
    return {
      transactions: [],

      isLoading: {
        pagination: true,
        expenses: true,
        action: false,
      },

      pagination: {
        page: 1,
        totalData: 0,
        totalPagination: 0,
      },

      tempSelectedTransaction: {
        id: null,
        role: null,
        type: null,
      },

      showModal: {
        confirmation: false,
        deletion: false,
      },
    };
  },

  async created() {
    this.isLoading.pagination = true;
    await this.fetchGetPropertyExpenses();
    this.pagination.totalPagination = Math.ceil(
      this.pagination.totalData / LIMIT
    );
    this.isLoading.pagination = false;
  },

  methods: {
    async fetchGetPropertyExpenses(query = '') {
      this.isLoading.expenses = true;
      const response = await endpoints.getPropertyExpenses(query);

      if (response) {
        this.transactions = response.property_expenses.map(item => ({
          ...item,
          expanded: false,
        }));

        this.pagination.totalData = response.metadata.total;
      }

      this.isLoading.expenses = false;
    },

    async fetchAction() {
      this.isLoading.action = true;
      let response = null;
      let toastMessage = null;

      if (this.tempSelectedTransaction.type === 'confirmation') {
        response = await this.confirmationAction();
        toastMessage = 'Data berhasil dikonfirmasi';
      } else {
        response = await endpoints.deleteExpenditure(
          this.tempSelectedTransaction.id
        );

        toastMessage = 'Data berhasil dihapus';
      }

      if (response) {
        this.closeModal();
        this.fetchGetPropertyExpenses(this.filterExpenditure);
        this.$toast.show(toastMessage);
      }

      this.isLoading.action = false;
    },

    async fetchGetExpenditureFilterVendor() {
      if (
        !this.filterLoading &&
        !(
          this.filterOptions.expenseCategory.length ||
          this.filterOptions.transferDestination.length
        )
      ) {
        this.filterLoading = true;
        const response = await endpoints.getExpenditureFilterVendor();

        if (response) {
          this.filterOptions.expenseCategory = response.expense_category.map(
            item => ({
              value: item.id,
              label: item.label,
            })
          );

          const internalVendors = response.vendors.internal.map(item => ({
            value: item.id,
            label: `${item.label} (Internal Mamikos)`,
          }));
          const externalVendors = response.vendors.external.map(item => ({
            value: item.id,
            label: `${item.label} (Vendor)`,
          }));

          this.filterOptions.transferDestination = [
            ...externalVendors,
            ...internalVendors,
          ];
        }
        this.filterLoading = false;
      }
    },

    confirmationAction() {
      const fetching = {
        manager: async () => {
          const response = await endpoints.postManagerApprove(
            this.tempSelectedTransaction.id
          );

          return response;
        },
        finance: async () => {
          const response = await endpoints.putFinanceApprove(
            this.tempSelectedTransaction.id
          );

          return response;
        },
      };

      return fetching[this.tempSelectedTransaction.role]();
    },

    expandContent(index) {
      this.transactions[index].expanded = !this.transactions[index].expanded;
    },

    async onSubmitSearch() {
      this.pagination.page = 1;
      this.filterValues.offset = 0;

      this.isLoading.pagination = true;

      await this.fetchGetPropertyExpenses(this.filterExpenditure);

      this.pagination.totalPagination = Math.ceil(
        this.pagination.totalData / LIMIT
      );

      this.isLoading.pagination = false;
    },

    onPaginationChange() {
      this.filterValues.offset = this.pagination.page * LIMIT - LIMIT;
      this.fetchGetPropertyExpenses(this.filterExpenditure);
    },

    async resetFilter(all) {
      this.isLoading.pagination = true;

      this.pagination.page = 1;
      this.filterValues.offset = 0;
      this.filterValues.modal.manager = [];
      this.filterValues.modal.finance = [];
      this.filterValues.modal.expenseCategory = [];
      this.filterValues.modal.transferDestination = [];
      this.filterValues.modal.startDate = null;
      this.filterValues.modal.endDate = null;

      if (all) {
        this.filterValues.search = '';
      }

      await this.fetchGetPropertyExpenses(this.filterExpenditure);

      this.pagination.totalPagination = Math.ceil(
        this.pagination.totalData / LIMIT
      );

      this.isLoading.pagination = false;
    },

    showActionModal(payload) {
      if (payload.type === 'confirmation') {
        this.showModal.confirmation = true;
      } else {
        this.showModal.deletion = true;
      }

      this.tempSelectedTransaction = {
        ...this.tempSelectedTransaction,
        ...payload,
      };
    },

    closeModal() {
      this.showModal = {
        confirmation: false,
        deletion: false,
      };
      this.tempSelectedTransaction = {
        id: null,
        role: null,
        type: null,
      };
    },
  },
};
</script>
